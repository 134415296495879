import PropTypes from 'prop-types';
import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Upload,
} from '@brooktec/react-ui-kit';

const { Dragger } = Upload;

function Dropzone({
  onChange,
}) {
  const beforeUpload = (file) => {
    onChange(file);
    return false;
  };

  return (
    <Dragger
      accept="application/pdf"
      beforeUpload={beforeUpload}
      maxCount={1}
      multiple={false}
      showUploadList={false}
    >
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Dragger>
  );
}

Dropzone.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default Dropzone;
