import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  DatePicker,
  Form,
  message,
  Space,
  Upload,
} from '@brooktec/react-ui-kit';

import FormInput from 'components/atoms/FormInput';
import {
  requestCreatePriceShareClassAction,
  requestCreatePriceShareClassDocumentAction,
} from 'store/slices/shareClassSlice';
import { UploadOutlined } from '@ant-design/icons';

function AddPrice({
  shareClassId,
}) {
  const dispatch = useDispatch();

  const onFormFinish = (
    values,
  ) => {
    values.date.utc().startOf('day');
    dispatch(
      requestCreatePriceShareClassAction({ shareClassId, values }),
    )
      .unwrap()
      .catch((error) => {
        message.error(error);
      });
  };

  const onDocumentUpload = (
    file,
  ) => {
    dispatch(
      requestCreatePriceShareClassDocumentAction({ shareClassId, file }),
    )
      .unwrap()
      .catch((error) => {
        message.error(error);
      });
  };

  return (
    <Card style={{ width: 300 }}>
      <Space direction="vertical">
        <h3>Add new price</h3>
        <Form
          autoComplete="off"
          onFinish={onFormFinish}
        >
          <Form.Item
            id="date"
            name="date"
            label="Date"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker />
          </Form.Item>

          <FormInput
            id="value"
            label="Price"
            placeholder="Value"
          />

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Create
              </Button>

              <Upload
                beforeUpload={(file) => {
                  onDocumentUpload(file);
                  return false;
                }}
                accept=".csv"
                maxCount={1}
                multiple={false}
                showUploadList={false}
              >
                <Button icon={<UploadOutlined />}>Upload CSV</Button>
              </Upload>
            </Space>
          </Form.Item>
        </Form>
      </Space>
    </Card>
  );
}

AddPrice.propTypes = {
  shareClassId: PropTypes.number.isRequired,
};

export default AddPrice;
