import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ShareClassForm from 'components/organisms/ShareClassForm';

import { useEffect } from 'react';
import Navbar from 'components/organisms/Navbar';
import { requestCreateShareClassAction } from 'store/slices/shareClassSlice';
import styles from './index.module.css';

function CreateShareClass() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const fundId = parseInt(searchParams.get('fund'), 10);

  const navigateToDashboard = () => {
    navigate('/');
  };

  useEffect(() => {
    if (!fundId) {
      navigateToDashboard();
    }
  }, []);

  const navigateToShareClass = (id) => {
    navigate(`/shareClass/${id}`, { replace: true });
  };

  const onFinish = (
    values,
  ) => {
    const valuesWithFund = values;
    valuesWithFund.fundId = fundId;
    return dispatch(requestCreateShareClassAction({ valuesWithFund }))
      .then((action) => navigateToShareClass(action.payload.id));
  };

  return (
    <>
      <Navbar title="Create share class" />
      <div className={styles.container}>
        <ShareClassForm onFinish={onFinish} />
      </div>
    </>
  );
}

export default CreateShareClass;
