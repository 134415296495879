import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import FundForm from 'components/organisms/FundForm';
import Navbar from 'components/organisms/Navbar';

import { requestCreateFundAction } from 'store/slices/fundSlice';
import styles from './index.module.css';

function CreateFund() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateToFund = (id) => {
    navigate(`/fund/${id}`, { replace: true });
  };

  const onFinish = (values) => dispatch(requestCreateFundAction({ values }))
    .then((action) => navigateToFund(action.payload.id));

  return (
    <>
      <Navbar title="Create fund" />
      <div className={styles.container}>
        <FundForm onFinish={onFinish} />
      </div>
    </>

  );
}

export default CreateFund;
