import { useDispatch, useSelector } from 'react-redux';

import { Spin } from '@brooktec/react-ui-kit';

import {
  requestDeleteSicavDocumentAction,
  requestEditSicavDocumentAction,
} from 'store/slices/constantsSlice';
import { useState } from 'react';
import DocumentsOverview from '../DocumentsOverview';

function SicavDocuments() {
  const {
    data: sicavs,
    isLoading,
    isSuccess,
  } = useSelector((state) => state.constants.sicavs);
  const dispatch = useDispatch();
  const [countryId, setCountryId] = useState();

  const onDocumentUpload = (id, documentId, file) => {
    const document = {
      id: documentId,
      file,
    };

    dispatch(requestEditSicavDocumentAction({ id, countryId, document }));
  };

  const deleteDocument = (
    sicavId,
    documentId,
  ) => dispatch(requestDeleteSicavDocumentAction({ sicavId, countryId, documentId }));

  return (
    <Spin spinning={isLoading}>
      {isSuccess && sicavs.map((sicav) => (
        <DocumentsOverview
          key={sicav.id}
          countryId={countryId}
          setCountryId={setCountryId}
          deleteDocument={(documentId) => deleteDocument(sicav.id, documentId)}
          onDocumentUpload={(documentId, file) => onDocumentUpload(sicav.id, documentId, file)}
          data={sicav.Documents}
          type="sicav"
          edit
        />
      ))}
    </Spin>
  );
}

export default SicavDocuments;
