import Dropzone from 'components/atoms/Dropzone';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import {
  Row, Col, Space, Select,
} from '@brooktec/react-ui-kit';

import { useSelector } from 'react-redux';
import DeleteButton from 'components/atoms/DeleteButton';
import styles from './index.module.css';

const { Option } = Select;

function DocumentsOverview({
  data,
  deleteDocument,
  edit,
  investorTypeId,
  onDocumentUpload,
  removeDocumentUpload,
  type,
  uploadedDocuments,
  countryId,
  setCountryId,
}) {
  const {
    data: documents = [],
    isSuccess,
  } = useSelector((state) => state.constants.documents);
  const { countries } = useSelector((state) => state.constants);

  const filteredDocuments = useMemo(() => {
    let tmpDocuments = documents.filter((d) => d.type === type);
    if (investorTypeId) {
      tmpDocuments = tmpDocuments.filter((d) => {
        const categoryInvestorTypeId = d.DocumentCategory.investorTypeId;
        return !categoryInvestorTypeId || categoryInvestorTypeId === investorTypeId;
      });
    }
    return tmpDocuments;
  }, [documents]);

  const typeCapitalize = type.charAt(0).toUpperCase() + type.slice(1);
  return (
    <div className={styles.container}>
      <Space>
        <h3>{`${typeCapitalize} documents`}</h3>
        <Select
          style={{ width: 130 }}
          placeholder="Country"
          value={countryId}
          onChange={setCountryId}
        >
          {countries.isSuccess && countries.data.map((country) => (
            <Option key={country.id} value={country.id}>
              {country.name}
            </Option>
          ))}
        </Select>
      </Space>
      {countryId && (
        <Row gutter={[16, 24]}>
          {isSuccess && filteredDocuments && filteredDocuments.map((document) => {
            const documentsKey = `${typeCapitalize}Documents`;
            const uploaded = uploadedDocuments?.find((d) => d.id === document.id);

            let existingDocument = data?.find((d) => d.id === document.id);
            if (existingDocument) {
              existingDocument = existingDocument[documentsKey]?.find(
                (d) => d.countryId === Number(countryId),
              );
            }

            return (
              <Col
                className={styles.uploadCard}
                key={document.id}
                span={8}
              >
                <Space className={styles.nameContainer}>
                  <b>{document.name}</b>
                  {removeDocumentUpload && (
                    <DeleteButton
                      disabled={!uploaded}
                      onConfirm={() => removeDocumentUpload(document.id)}
                      popTitle="Are you sure you want to cancel document upload?"
                      secondary
                    />
                  )}
                  {deleteDocument && (
                    <DeleteButton
                      disabled={!existingDocument}
                      onConfirm={() => deleteDocument(document.id)}
                      popTitle="Are you sure to delete this document?"
                    />
                  )}
                </Space>
                {
                  // eslint-disable-next-line no-nested-ternary
                  uploaded
                    ? (
                      <a
                        href={URL.createObjectURL(uploaded.file)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Uploaded document
                      </a>
                    )
                    : existingDocument
                      ? <a href={existingDocument.url}>Download document</a>
                      : <span>No document uploaded</span>
                }
                {edit && (
                  <Dropzone
                    onChange={(file) => onDocumentUpload(document.id, file)}
                  />
                )}
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
}

DocumentsOverview.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  deleteDocument: PropTypes.func,
  edit: PropTypes.bool,
  investorTypeId: PropTypes.number,
  onDocumentUpload: PropTypes.func,
  removeDocumentUpload: PropTypes.func,
  type: PropTypes.string.isRequired,
  uploadedDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      // Type File
      // eslint-disable-next-line react/forbid-prop-types
      file: PropTypes.object.isRequired,
    }),
  ),
  countryId: PropTypes.number.isRequired,
  setCountryId: PropTypes.func.isRequired,
};

DocumentsOverview.defaultProps = {
  data: undefined,
  deleteDocument: undefined,
  investorTypeId: undefined,
  edit: false,
  onDocumentUpload: undefined,
  removeDocumentUpload: undefined,
  uploadedDocuments: undefined,
};

export default DocumentsOverview;
