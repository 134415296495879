import PropTypes from 'prop-types';
import {
  Form,
  Input,
} from '@brooktec/react-ui-kit';

function FormInput({
  id,
  initialValue,
  label,
  placeholder,
  required,
  requiredText,
}) {
  return (
    <Form.Item
      name={id}
      label={label}
      initialValue={initialValue}
      rules={[
        {
          required, message: requiredText,
        },
      ]}
    >
      <Input placeholder={placeholder} />
    </Form.Item>
  );
}

FormInput.propTypes = {
  id: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  requiredText: PropTypes.string,
};

FormInput.defaultProps = {
  initialValue: undefined,
  required: true,
  requiredText: undefined,
};

export default FormInput;
