import axios from 'axios';
import jwtDecode from 'jwt-decode';

const makeCall = ({
  method = 'GET',
  path,
  data,
  params,
  withoutToken = false,
  multipart = false,
}) => new Promise((resolve, reject) => {
  const API_URL = process.env.REACT_APP_ALLFUNDS_IS_API;

  const url = `${API_URL}${path}`;

  const headers = {};
  if (!withoutToken) {
    const token = localStorage.getItem('token');
    if (!token) {
      reject();
      return;
    }
    headers.Authorization = `Bearer ${token}`;
  }

  if (multipart) {
    headers.responseType = 'arraybuffer';
    headers['Content-Type'] = 'multipart/form-data';
    headers.Accept = 'application/pdf';
  }

  axios.request({
    method,
    url,
    data,
    headers,
    params,
  })
    .then((response) => resolve(response))
    .catch((error) => {
      const { status } = error.response;
      if (status === 401) {
        localStorage.removeItem('token');
        window.location.href = window.location.origin;
      }
      reject(error);
    });
});

const requestLogin = (username, password) => {
  const data = {
    username,
    password,
  };

  return makeCall({
    method: 'POST',
    path: '/auth/login',
    data,
    withoutToken: true,
  }).then((response) => {
    const { token } = response.data;
    localStorage.setItem('token', token);
    const user = jwtDecode(token);

    return user;
  });
};

const requestLogout = () => makeCall({
  method: 'POST',
  path: '/auth/logout',
}).then(() => {
  localStorage.removeItem('token');
});

const requestFunds = (params) => makeCall({
  method: 'GET',
  path: '/fund',
  params,
}).then((response) => response.data.funds);

const requestCreateFund = (data) => {
  const formData = new FormData();

  formData.append('fund', JSON.stringify(data));

  return makeCall({
    method: 'POST',
    path: '/fund',
    data: formData,
    multipart: true,
  }).then((response) => response.data.fund);
};

const requestEditFund = (id, data) => {
  const formData = new FormData();

  formData.append('fund', JSON.stringify(data));

  return makeCall({
    method: 'PUT',
    path: `/fund/${id}`,
    data: formData,
    multipart: true,
  }).then((response) => response.data.fund);
};

const requestEditFundDocument = (id, countryId, document) => {
  const formData = new FormData();

  formData.append('countryId', countryId);
  formData.append(document.id, document.file);

  return makeCall({
    method: 'PUT',
    path: `/fund/${id}/documents`,
    data: formData,
    multipart: true,
  }).then((response) => response.data.fund);
};

const requestEditShareClassDocument = (id, countryId, document) => {
  const formData = new FormData();

  formData.append('countryId', countryId);
  formData.append(document.id, document.file);

  return makeCall({
    method: 'PUT',
    path: `/shareClass/${id}/documents`,
    data: formData,
    multipart: true,
  }).then((response) => response.data.shareClass);
};

const requestFund = (id) => makeCall({
  method: 'GET',
  path: `/fund/${id}`,
}).then((response) => response.data.fund);

const requestShareClass = (id) => makeCall({
  method: 'GET',
  path: `/shareClass/${id}`,
}).then((response) => response.data.shareClass);

const requestCreateShareClass = (data) => {
  const formData = new FormData();

  formData.append('shareClass', JSON.stringify(data));

  return makeCall({
    method: 'POST',
    path: '/shareClass',
    data: formData,
    multipart: true,
  }).then((response) => response.data.shareClass);
};

const requestEditShareClass = (id, data) => {
  const formData = new FormData();

  formData.append('shareClass', JSON.stringify(data));

  return makeCall({
    method: 'PUT',
    path: `/shareClass/${id}`,
    data: formData,
    multipart: true,
  }).then((response) => response.data.shareClass);
};

const requestDeleteShareClass = (id) => makeCall({
  method: 'DELETE',
  path: `/shareClass/${id}`,
}).then((response) => response.data.shareClass);

const requestCreateDocument = (data) => makeCall({
  method: 'POST',
  path: '/document',
  data,
}).then((response) => response.data.document);

const requestCreateCountry = (data) => makeCall({
  method: 'POST',
  path: '/country',
  data,
}).then((response) => response.data.country);

const requestCreateCurrency = (data) => makeCall({
  method: 'POST',
  path: '/currency',
  data,
}).then((response) => response.data.currency);

const requestDeleteFund = (id) => makeCall({
  method: 'DELETE',
  path: `/fund/${id}`,
}).then((response) => response.data.fund);

const requestDeleteFundDocument = (fundId, countryId, documentId) => makeCall({
  method: 'DELETE',
  path: `/fund/${fundId}/document/${documentId}/country/${countryId}`,
}).then((response) => response.data.fund);

const requestDeleteSicavDocument = (sicavId, countryId, documentId) => makeCall({
  method: 'DELETE',
  path: `/sicav/${sicavId}/document/${documentId}/country/${countryId}`,
}).then((response) => response.data.sicav);

const requestDeleteShareClassDocument = (shareClassId, countryId, documentId) => makeCall({
  method: 'DELETE',
  path: `/shareClass/${shareClassId}/document/${documentId}/country/${countryId}`,
}).then((response) => response.data.shareClass);

const requestDeletePrice = (id) => makeCall({
  method: 'DELETE',
  path: `/price/${id}`,
});

const requestDeleteDocument = (documentId) => makeCall({
  method: 'DELETE',
  path: `/document/${documentId}`,
}).then((response) => response.data.document);

const requestDeleteCountry = (countryId) => makeCall({
  method: 'DELETE',
  path: `/country/${countryId}`,
}).then((response) => response.data.country);

const requestDeleteCurrency = (currencyId) => makeCall({
  method: 'DELETE',
  path: `/currency/${currencyId}`,
}).then((response) => response.data.currency);

const requestCountries = () => makeCall({
  method: 'GET',
  path: '/country',
}).then((response) => response.data.countries);

const requestCurrencies = () => makeCall({
  method: 'GET',
  path: '/currency',
}).then((response) => response.data.currencies);

const requestDocumentCategories = () => makeCall({
  method: 'GET',
  path: '/documentCategory',
}).then((response) => response.data.documentCategories);

const requestInvestorTypes = () => makeCall({
  method: 'GET',
  path: '/investorType',
}).then((response) => response.data.investorTypes);

const requestSicavs = () => makeCall({
  method: 'GET',
  path: '/sicav',
}).then((response) => response.data.sicavs);

const requestEditSicavDocument = (id, countryId, document) => {
  const formData = new FormData();

  formData.append(document.id, document.file);
  formData.append('countryId', countryId);

  return makeCall({
    method: 'PUT',
    path: `/sicav/${id}`,
    data: formData,
    multipart: true,
  }).then((response) => response.data.sicav);
};

const requestDocuments = (params) => makeCall({
  method: 'GET',
  path: '/document',
  params,
}).then((response) => response.data.documents);

const requestToggleActiveFund = async (id, active) => makeCall({
  method: 'PATCH',
  path: `/fund/${id}`,
  data: { active },
}).then((response) => response.data.fund);

const requestToggleActiveShareClass = (id, active) => makeCall({
  method: 'PATCH',
  path: `/shareClass/${id}`,
  data: { active },
}).then((response) => response.data.active);

const requestCreatePriceShareClass = (shareClassId, data) => makeCall({
  method: 'POST',
  path: `/price/${shareClassId}`,
  data,
}).then((response) => response.data.price);

const requestCreatePriceShareClassDocument = (shareClassId, file) => {
  const formData = new FormData();

  formData.append('file', file);

  return makeCall({
    method: 'POST',
    path: `/price/${shareClassId}/document`,
    data: formData,
    multipart: true,
  }).then((response) => response.data.prices);
};

const requestPrices = (shareClassId, params) => makeCall({
  path: `/price/${shareClassId}`,
  params,
}).then((response) => response.data.prices);

export {
  requestCountries,
  requestCreateCountry,
  requestCreateCurrency,
  requestCreateDocument,
  requestCreateFund,
  requestCreatePriceShareClass,
  requestCreatePriceShareClassDocument,
  requestCreateShareClass,
  requestCurrencies,
  requestDeleteCountry,
  requestDeleteCurrency,
  requestDeleteDocument,
  requestDeleteFund,
  requestDeleteFundDocument,
  requestDeletePrice,
  requestDeleteShareClass,
  requestDeleteShareClassDocument,
  requestDeleteSicavDocument,
  requestDocumentCategories,
  requestDocuments,
  requestEditFund,
  requestEditShareClass,
  requestEditSicavDocument,
  requestEditFundDocument,
  requestEditShareClassDocument,
  requestFund,
  requestFunds,
  requestInvestorTypes,
  requestLogin,
  requestLogout,
  requestPrices,
  requestShareClass,
  requestSicavs,
  requestToggleActiveFund,
  requestToggleActiveShareClass,
};
