import {
  EuroOutlined,
  FileOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from '@brooktec/react-ui-kit';
import CountrySettings from 'components/organisms/CountrySettings';
import CurrencySettings from 'components/organisms/CurrencySettings';
import DocumentSettings from 'components/organisms/DocumentSettings';

import Navbar from 'components/organisms/Navbar';
import { useMemo, useState } from 'react';

import styles from './index.module.css';

const { Content, Sider } = Layout;

function Settings() {
  const [index, setIndex] = useState('1');

  const handleClick = (e) => {
    setIndex(e.key);
  };

  const content = useMemo(() => {
    switch (index) {
      case '1':
        return <CountrySettings />;

      case '2':
        return <DocumentSettings />;

      case '3':
        return <CurrencySettings />;

      default:
        return undefined;
    }
  }, [index]);

  return (
    <div className={styles.container}>
      <Navbar title="Settings" />
      <Layout>
        <Sider>
          <Menu
            onClick={handleClick}
            defaultSelectedKeys={[index]}
            mode="inline"
          >
            <Menu.Item key="1" icon={<PieChartOutlined />}>
              Countries
            </Menu.Item>
            <Menu.Item key="2" icon={<FileOutlined />}>
              Documents
            </Menu.Item>
            <Menu.Item key="3" icon={<EuroOutlined />}>
              Currencies
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Content>
            {content}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default Settings;
