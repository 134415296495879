import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Form,
  message,
  // message,
} from '@brooktec/react-ui-kit';

import { clearErrorsAction, requestCreateCountryAction } from 'store/slices/constantsSlice';

import FormInput from 'components/atoms/FormInput';
import { useEffect } from 'react';

function AddCountry() {
  const dispatch = useDispatch();
  const {
    error,
    isError,
  } = useSelector((state) => state.constants.countries);

  const onFormFinish = (values) => dispatch(requestCreateCountryAction(values));

  useEffect(() => {
    if (isError) {
      message.error(error);
      dispatch(clearErrorsAction());
    }
  }, [isError]);

  return (
    <Card>
      <h3>Add new country</h3>
      <Form
        autoComplete="off"
        onFinish={onFormFinish}
      >
        <FormInput
          id="name"
          label="Country name"
          placeholder="Name"
        />

        <FormInput
          id="code"
          label="Country code"
          placeholder="Code"
        />

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default AddCountry;
