import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Card,
  Form,
  message,
} from '@brooktec/react-ui-kit';

import { clearErrorsAction, requestCreateDocumentAction } from 'store/slices/constantsSlice';

import FormInput from 'components/atoms/FormInput';
import FormSelect from 'components/atoms/FormSelect';
import { useEffect } from 'react';

function AddDocumentType() {
  const dispatch = useDispatch();
  const {
    error,
    isError,
  } = useSelector((state) => state.constants.documents);

  const {
    data: documentCategories,
  } = useSelector((state) => state.constants.documentCategories);

  useEffect(() => {
    if (isError) {
      message.error(error);
      dispatch(clearErrorsAction());
    }
  }, [isError]);

  const onFormFinish = (values) => dispatch(requestCreateDocumentAction(values));

  return (
    <Card>
      <h3>Add new document type</h3>
      <Form
        autoComplete="off"
        onFinish={onFormFinish}
      >
        <FormInput
          id="name"
          label="Document name"
          placeholder="Name"
        />

        <FormSelect
          data={[
            { id: 'sicav', name: 'Sicav' },
            { id: 'fund', name: 'Fund' },
            { id: 'shareClass', name: 'Share Class' },
          ]}
          id="type"
          label="Type of document"
          placeholder="Select a type of document"
        />

        {documentCategories && (
          <FormSelect
            data={documentCategories}
            id="documentCategoryId"
            label="Category of document"
            placeholder="Select a category"
          />
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default AddDocumentType;
