import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  Spin,
} from '@brooktec/react-ui-kit';

import { requestEditShareClassAction, requestShareClassAction } from 'store/slices/shareClassSlice';

import ShareClassForm from 'components/organisms/ShareClassForm';
import Navbar from 'components/organisms/Navbar';

import styles from './index.module.css';

function EditShareClass() {
  const params = useParams();
  const shareClassId = parseInt(params.id, 10);

  const dispatch = useDispatch();
  const {
    data: shareClass,
    isLoading,
  } = useSelector((state) => state.shareClass);

  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate('/');
  };

  const navigateToShareClass = () => {
    navigate(`/shareClass/${shareClassId}`);
  };

  useEffect(() => {
    if (shareClass?.id !== shareClassId) {
      dispatch(requestShareClassAction(shareClassId))
        .unwrap()
        .catch(() => {
          navigateToDashboard();
        });
    }
  }, [shareClass]);

  const onFinish = (
    values,
  ) => dispatch(requestEditShareClassAction({
    id: shareClassId,
    fundId: shareClass.fundId,
    values,
  }))
    .then(navigateToShareClass);

  return (
    <>
      <Navbar title="Edit share class" />
      <Spin spinning={isLoading}>
        {shareClass?.id === shareClassId && (
          <div className={styles.container}>
            <ShareClassForm shareClass={shareClass} onFinish={onFinish} />
          </div>
        )}
      </Spin>
    </>
  );
}

export default EditShareClass;
