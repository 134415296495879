import PropTypes from 'prop-types';

import {
  Space,
  Spin,
  Table,
} from '@brooktec/react-ui-kit';

import DeleteButton from 'components/atoms/DeleteButton';
import {
  useDispatch,
} from 'react-redux';
import { formatDate } from 'utils';
import { requestDeletePriceAction, requestPricesAction } from 'store/slices/shareClassSlice';
import { useEffect, useState } from 'react';

const DEFAULT_PAGESIZE = 5;

function PriceTable({ shareClass }) {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const deletePrice = (price) => dispatch(requestDeletePriceAction(price));

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => {
        const formattedDate = new Date(date);
        return formatDate(formattedDate);
      },
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Price',
      dataIndex: 'value',
      key: 'value',
      render: (value) => `${value}€`,
      sorter: (a, b) => a.value - b.value,
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (price) => (
        <Space>
          <DeleteButton
            onConfirm={() => deletePrice(price)}
            popTitle="Are you sure to delete this price?"
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!shareClass.prices
      || (page * DEFAULT_PAGESIZE > shareClass.prices.rows.length - 1
        && shareClass.prices.rows.length !== shareClass.prices.count)) {
      setLoading(true);

      const params = {
        limit: DEFAULT_PAGESIZE,
        offset: (page - 1) * DEFAULT_PAGESIZE,
      };

      dispatch(requestPricesAction({ id: shareClass.id, params }))
        .finally(() => setLoading(false));
    }
  }, [page]);

  return (
    <Spin spinning={isLoading}>
      <Table
        columns={columns}
        dataSource={shareClass.prices?.rows}
        rowKey="id"
        pagination={{
          defaultPageSize: DEFAULT_PAGESIZE,
          total: shareClass.prices?.count,
          onChange: setPage,
        }}
      />
    </Spin>
  );
}

PriceTable.propTypes = {
  shareClass: PropTypes.shape({
    id: PropTypes.number.isRequired,
    prices: PropTypes.shape({
      count: PropTypes.number.isRequired,
      rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    }),
  }).isRequired,
};

export default PriceTable;
