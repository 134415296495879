import PropTypes from 'prop-types';
import { useState } from 'react';

import {
  CloseOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {
  Button,
  message,
  Popconfirm,
} from '@brooktec/react-ui-kit';

import styles from './index.module.css';

function DeleteButton({
  disabled,
  errorMessage,
  onConfirm,
  popTitle,
  secondary,
}) {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const confirm = async () => {
    setIsLoadingDelete(true);
    try {
      await onConfirm();
    } catch (error) {
      const { status } = error.response;
      if (errorMessage && status === 409) {
        message.error(errorMessage);
      } else {
        message.error('Report error please');
      }
    } finally {
      setIsLoadingDelete(false);
      setShowDeletePopup(false);
    }
  };

  const cancel = () => {
    setShowDeletePopup(false);
  };

  return (
    <div className={styles.container}>
      <Popconfirm
        title={popTitle}
        onConfirm={confirm}
        onCancel={cancel}
        okButtonProps={{ loading: isLoadingDelete }}
        visible={showDeletePopup}
      >
        <Button
          icon={secondary ? <CloseOutlined /> : <DeleteOutlined />}
          onClick={() => setShowDeletePopup(true)}
          disabled={disabled}
        />
      </Popconfirm>
    </div>

  );
}

DeleteButton.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  popTitle: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
};

DeleteButton.defaultProps = {
  disabled: false,
  errorMessage: undefined,
  secondary: false,
};

export default DeleteButton;
