import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  requestCreateFund,
  requestDeleteFund,
  requestDeleteFundDocument,
  requestEditFund,
  requestEditFundDocument,
  requestFund,
  requestToggleActiveFund,
} from 'services/api';
import { deleteDashboardFundAction, toggleDashboardFundActiveAction } from './dashboardSlice';

const initialState = {
  data: undefined,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const requestFundAction = createAsyncThunk(
  'fund/requestFundAction',
  requestFund,
);

export const requestCreateFundAction = createAsyncThunk(
  'fund/requestCreateFundAction',
  async (params) => {
    const {
      values,
    } = params;

    const fund = await requestCreateFund(values);
    return requestFund(fund.id);
  },
);

export const requestDeleteFundAction = createAsyncThunk(
  'fund/requestDeleteFundAction',
  async (id, { dispatch }) => {
    await requestDeleteFund(id);
    dispatch(deleteDashboardFundAction(id));
  },
);

export const requestDeleteFundDocumentAction = createAsyncThunk(
  'fund/requestDeleteFundDocumentAction',
  async (params, { dispatch }) => {
    const {
      id,
      documentId,
      countryId,
    } = params;

    await requestDeleteFundDocument(id, countryId, documentId);
    dispatch(requestFundAction(id));
  },
);

export const requestEditFundAction = createAsyncThunk(
  'fund/requestEditFundAction',
  async (params, { dispatch }) => {
    const {
      id,
      values,

    } = params;

    await requestEditFund(id, values);
    dispatch(requestFundAction(id));
  },
);

export const requestEditFundDocumentAction = createAsyncThunk(
  'fund/requestEditFundDocumentAction',
  async (params, { dispatch }) => {
    const {
      id,
      countryId,
      document,
    } = params;

    await requestEditFundDocument(id, countryId, document);
    dispatch(requestFundAction(id));
  },
);

export const requestToggleActiveFundAction = createAsyncThunk(
  'fund/requestToggleActiveFundAction',
  async (params, { dispatch }) => {
    const {
      id,
      active,
    } = params;

    await requestToggleActiveFund(id, active);
    dispatch(toggleDashboardFundActiveAction(params));
    return params;
  },
);

export const fundSlice = createSlice({
  name: 'fund',
  initialState,
  reducers: {
    createFundShareClass: (state, action) => {
      if (state.data) { state.data.ShareClasses.push(action.payload); }
    },
    deleteFundShareClassAction: (state, action) => {
      if (state.data) {
        const deletedShareClassId = action.payload;

        state.data.ShareClasses = state.data.ShareClasses.filter((
          s,
        ) => s.id !== deletedShareClassId);
      }
    },
    toggleFundShareClassActiveAction: (state, action) => {
      if (state.data) {
        const response = action.payload;

        state.data.ShareClasses = state.data.ShareClasses.map((s) => (s.id === response.id ? ({
          ...s,
          active: response.active,
        }) : s));
      }
    },
  },
  extraReducers: {
    [requestFundAction.pending]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [requestFundAction.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [requestFundAction.rejected]: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    [requestCreateFundAction.pending]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [requestCreateFundAction.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [requestCreateFundAction.rejected]: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    [requestDeleteFundAction.pending]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [requestDeleteFundAction.fulfilled]: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [requestDeleteFundAction.rejected]: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    [requestDeleteFundDocumentAction.pending]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [requestDeleteFundDocumentAction.fulfilled]: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [requestDeleteFundDocumentAction.rejected]: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    [requestEditFundAction.pending]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [requestEditFundAction.fulfilled]: () => {
    },
    [requestEditFundAction.rejected]: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    [requestToggleActiveFundAction.pending]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [requestToggleActiveFundAction.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      const response = action.payload;

      if (state.data?.id === response.id) {
        state.data.active = response.active;
      }
    },
    [requestToggleActiveFundAction.rejected]: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
  },
});

export const {
  createFundShareClass,
  deleteFundShareClassAction,
  toggleFundShareClassActiveAction,
} = fundSlice.actions;

export default fundSlice.reducer;
