import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  requestCountries,
  requestCreateCountry,
  requestCreateDocument,
  requestCreateCurrency,
  requestCurrencies,
  requestDeleteCountry,
  requestDeleteDocument,
  requestDeleteSicavDocument,
  requestDocumentCategories,
  requestDocuments,
  requestEditSicavDocument,
  requestInvestorTypes,
  requestSicavs,
  requestDeleteCurrency,
} from 'services/api';

const initialState = {
  countries: {
    data: undefined,
    error: undefined,
    isError: false,
    isLoading: false,
    isSuccess: false,
  },
  investorTypes: {
    data: undefined,
    error: undefined,
    isError: false,
    isLoading: false,
    isSuccess: false,
  },
  sicavs: {
    data: undefined,
    error: undefined,
    isError: false,
    isLoading: false,
    isSuccess: false,
  },
  documents: {
    data: undefined,
    error: undefined,
    isError: false,
    isLoading: false,
    isSuccess: false,
  },
  currencies: {
    data: undefined,
    error: undefined,
    isError: false,
    isLoading: false,
    isSuccess: false,
  },
  documentCategories: {
    data: undefined,
    error: undefined,
    isError: false,
    isLoading: false,
    isSuccess: false,
  },
};

export const requestCountriesAction = createAsyncThunk(
  'constants/requestCountriesAction',
  requestCountries,
);

export const requestCurrenciesAction = createAsyncThunk(
  'constants/requestCurrenciesAction',
  requestCurrencies,
);

export const requestDocumentsAction = createAsyncThunk(
  'constants/requestDocumentsAction',
  requestDocuments,
);

export const requestDocumentCategoriesAction = createAsyncThunk(
  'constants/requestDocumentCategoriesAction',
  requestDocumentCategories,
);

export const requestSicavsAction = createAsyncThunk(
  'constants/requestSicavsAction',
  requestSicavs,
);

export const requestInvestorTypesAction = createAsyncThunk(
  'constants/requestInvestorTypesAction',
  requestInvestorTypes,
);

export const requestConstantsAction = createAsyncThunk(
  'constants/requestConstantsAction',
  (_, { dispatch }) => Promise.all([
    dispatch(requestCountriesAction()),
    dispatch(requestCurrenciesAction()),
    dispatch(requestDocumentsAction()),
    dispatch(requestDocumentCategoriesAction()),
    dispatch(requestInvestorTypesAction()),
    dispatch(requestSicavsAction()),
  ]),
);

export const requestEditSicavDocumentAction = createAsyncThunk(
  'constants/requestEditSicavDocumentAction',
  async (params, { dispatch }) => {
    const {
      id,
      countryId,
      document,
    } = params;

    await requestEditSicavDocument(id, countryId, document);
    dispatch(requestSicavsAction());
  },
);

export const requestDeleteSicavDocumentAction = createAsyncThunk(
  'constants/requestDeleteSicavDocumentAction',
  async (params, { dispatch }) => {
    const {
      sicavId,
      countryId,
      documentId,
    } = params;

    await requestDeleteSicavDocument(sicavId, countryId, documentId);
    dispatch(requestSicavsAction());
  },
);

export const requestDeleteCountryAction = createAsyncThunk(
  'constants/requestDeleteCountryAction',
  async (id, { dispatch }) => {
    await requestDeleteCountry(id);
    dispatch(requestCountriesAction());
  },
);

export const requestCreateCountryAction = createAsyncThunk(
  'constants/requestCreateCountryAction',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      await requestCreateCountry(params);
      dispatch(requestCountriesAction());
    } catch (error) {
      const errorMessage = error.response.data.message;
      throw rejectWithValue(errorMessage);
    }
  },
);

export const requestDeleteDocumentAction = createAsyncThunk(
  'constants/requestDeleteDocumentAction',
  async (id, { dispatch }) => {
    await requestDeleteDocument(id);
    dispatch(requestDocumentsAction());
  },
);

export const requestCreateDocumentAction = createAsyncThunk(
  'constants/requestCreateDocumentAction',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      await requestCreateDocument(params);
      dispatch(requestDocumentsAction());
    } catch (error) {
      const errorMessage = error.response.data.message;
      throw rejectWithValue(errorMessage);
    }
  },
);

export const requestCreateCurrencyAction = createAsyncThunk(
  'constants/requestCreateCurrencyAction',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      await requestCreateCurrency(params);
      dispatch(requestCurrenciesAction());
    } catch (error) {
      const errorMessage = error.response.data.message;
      throw rejectWithValue(errorMessage);
    }
  },
);

export const requestDeleteCurrencyAction = createAsyncThunk(
  'constants/requestDeleteCurrencyAction',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      await requestDeleteCurrency(id);
      dispatch(requestCurrenciesAction());
    } catch (error) {
      const errorMessage = error.response.data.message;
      throw rejectWithValue(errorMessage);
    }
  },
);

export const constantsSlice = createSlice({
  name: 'constants',
  initialState,
  reducers: {
    clearErrorsAction: (state) => {
      state.countries.isError = false;
      state.documents.isError = false;
      state.investorTypes.isError = false;
      state.sicavs.isError = false;
    },
  },
  extraReducers: {
    [requestCountriesAction.pending]: (state) => {
      state.countries.isLoading = true;
      state.countries.isError = false;
    },
    [requestCountriesAction.fulfilled]: (state, action) => {
      state.countries.isLoading = false;
      state.countries.isSuccess = true;
      state.countries.data = action.payload;
    },
    [requestCountriesAction.rejected]: (state) => {
      state.countries.isLoading = false;
      state.countries.isError = true;
    },
    [requestCurrenciesAction.pending]: (state) => {
      state.currencies.isLoading = true;
      state.currencies.isError = false;
    },
    [requestCurrenciesAction.fulfilled]: (state, action) => {
      state.currencies.isLoading = false;
      state.currencies.isSuccess = true;
      state.currencies.data = action.payload;
    },
    [requestCurrenciesAction.rejected]: (state) => {
      state.currencies.isLoading = false;
      state.currencies.isError = true;
    },
    [requestDocumentsAction.pending]: (state) => {
      state.documents.isLoading = true;
      state.documents.isError = false;
    },
    [requestDocumentsAction.fulfilled]: (state, action) => {
      state.documents.isLoading = false;
      state.documents.isSuccess = true;
      state.documents.data = action.payload;
    },
    [requestDocumentsAction.rejected]: (state) => {
      state.documents.isLoading = false;
      state.documents.isError = true;
    },
    [requestDocumentCategoriesAction.pending]: (state) => {
      state.documentCategories.isLoading = true;
      state.documentCategories.isError = false;
    },
    [requestDocumentCategoriesAction.fulfilled]: (state, action) => {
      state.documentCategories.isLoading = false;
      state.documentCategories.isSuccess = true;
      state.documentCategories.data = action.payload;
    },
    [requestDocumentCategoriesAction.rejected]: (state) => {
      state.documentCategories.isLoading = false;
      state.documentCategories.isError = true;
    },
    [requestInvestorTypesAction.pending]: (state) => {
      state.investorTypes.isLoading = true;
      state.investorTypes.isError = false;
    },
    [requestInvestorTypesAction.fulfilled]: (state, action) => {
      state.investorTypes.isLoading = false;
      state.investorTypes.isSuccess = true;
      state.investorTypes.data = action.payload;
    },
    [requestInvestorTypesAction.rejected]: (state) => {
      state.investorTypes.isLoading = false;
      state.investorTypes.isError = true;
    },
    [requestSicavsAction.pending]: (state) => {
      state.sicavs.isLoading = true;
      state.sicavs.isError = false;
    },
    [requestSicavsAction.fulfilled]: (state, action) => {
      state.sicavs.isLoading = false;
      state.sicavs.isSuccess = true;
      state.sicavs.data = action.payload;
    },
    [requestSicavsAction.rejected]: (state) => {
      state.sicavs.isLoading = false;
      state.sicavs.isError = true;
    },
    [requestEditSicavDocumentAction.pending]: (state) => {
      state.sicavs.isLoading = true;
    },
    [requestDeleteSicavDocumentAction.pending]: (state) => {
      state.sicavs.isLoading = true;
    },
    [requestDeleteCountryAction.pending]: (state) => {
      state.countries.isLoading = true;
    },
    [requestCreateCountryAction.pending]: (state) => {
      state.countries.isLoading = true;
      state.countries.isError = false;
    },
    [requestCreateCountryAction.rejected]: (state, action) => {
      state.countries.isLoading = false;
      state.countries.isError = true;
      state.countries.error = action.payload;
    },
    [requestDeleteDocumentAction.pending]: (state) => {
      state.documents.isLoading = true;
    },
    [requestCreateDocumentAction.pending]: (state) => {
      state.documents.isLoading = true;
      state.documents.isError = false;
    },
    [requestCreateDocumentAction.rejected]: (state, action) => {
      state.documents.isLoading = false;
      state.documents.isError = true;
      state.documents.error = action.payload;
    },
    [requestCreateCurrencyAction.pending]: (state) => {
      state.currencies.isLoading = true;
      state.currencies.isError = false;
    },
    [requestCreateCurrencyAction.rejected]: (state, action) => {
      state.currencies.isLoading = false;
      state.currencies.isError = true;
      state.currencies.error = action.payload;
    },
    [requestDeleteCurrencyAction.pending]: (state) => {
      state.currencies.isLoading = true;
    },
    [requestDeleteCurrencyAction.rejected]: (state) => {
      state.currencies.isLoading = false;
      state.currencies.isError = true;
    },
  },
});

export const {
  clearErrorsAction,
} = constantsSlice.actions;

export default constantsSlice.reducer;
