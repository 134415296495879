import { configureStore } from '@reduxjs/toolkit';

import constantsReducer from './slices/constantsSlice';
import dashboardReducer from './slices/dashboardSlice';
import fundReducer from './slices/fundSlice';
import optionsReducer from './slices/optionsSlice';
import shareClassReducer from './slices/shareClassSlice';

export default configureStore({
  reducer: {
    constants: constantsReducer,
    dashboard: dashboardReducer,
    fund: fundReducer,
    options: optionsReducer,
    shareClass: shareClassReducer,
  },
});
