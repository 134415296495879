import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';
import { useIdleTimer } from 'react-idle-timer';

function RequireAuth() {
  const { user, logout } = useAuth();

  useIdleTimer({
    timeout: 15 * 60 * 1000,
    onIdle: logout,
    debounce: 1000,
  });

  return user
    ? <Outlet />
    : (
      <Navigate
        to="/login"
        replace
      />
    );
}

export default RequireAuth;
