import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import {
  Spin,
} from '@brooktec/react-ui-kit';

import {
  requestEditFundAction,
  requestFundAction,
} from 'store/slices/fundSlice';

import FundForm from 'components/organisms/FundForm';
import Navbar from 'components/organisms/Navbar';

import styles from './index.module.css';

function EditFund() {
  const params = useParams();
  const fundId = parseInt(params.id, 10);

  const dispatch = useDispatch();
  const {
    data: fund,
    isLoading,
  } = useSelector((state) => state.fund);

  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate('/');
  };

  const navigateToFund = () => {
    navigate(`/fund/${fundId}`);
  };

  useEffect(() => {
    if (fund?.id !== fundId) {
      dispatch(requestFundAction(fundId))
        .unwrap()
        .catch(() => {
          navigateToDashboard();
        });
    }
  }, [fund]);

  const onFinish = (
    values,
  ) => dispatch(requestEditFundAction({ id: fundId, values }))
    .then(navigateToFund);

  return (
    <>
      <Navbar title="Edit fund" />
      <Spin spinning={isLoading}>
        <div className={styles.container}>
          {fund?.id === fundId && (
            <FundForm fund={fund} onFinish={onFinish} />
          )}
        </div>
      </Spin>
    </>
  );
}

export default EditFund;
