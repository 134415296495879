import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedCountry: undefined,
  selectedInvestorType: undefined,
};

export const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    setSelectedCountryAction: (state, action) => {
      state.selectedCountry = action.payload;
    },
    setSelectedInvestorTypeAction: (state, action) => {
      state.selectedInvestorType = action.payload;
    },
  },
});

export const {
  setSelectedCountryAction,
  setSelectedInvestorTypeAction,
} = optionsSlice.actions;

export default optionsSlice.reducer;
