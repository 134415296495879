import PropTypes from 'prop-types';
import {
  useState,
  useContext,
  createContext,
} from 'react';
import { sha512 } from 'js-sha512';

import { requestLogin, requestLogout } from 'services/api';
import jwtDecode from 'jwt-decode';

// Hook
const useProvideAuth = () => {
  const checkToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
      return jwtDecode(token);
    }
    return undefined;
  };

  const [user, setUser] = useState(checkToken());
  const [loading, setLoading] = useState(false);

  const login = (username, password) => new Promise((resolve, reject) => {
    setLoading(true);

    const hashedPassword = sha512(password);

    requestLogin(username, hashedPassword)
      .then((loggedUser) => {
        setUser(loggedUser);
        setLoading(false);
        resolve();
      })
      .catch((error) => {
        setLoading(false);
        reject(error);
      });
  });
  const logout = () => new Promise((resolve, reject) => {
    requestLogout()
      .then(() => {
        setUser(undefined);
        setLoading(false);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

  return {
    user,
    login,
    logout,
    loading,
  };
};

// Create hook context
const AuthContext = createContext(null);

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}

ProvideAuth.propTypes = {
  children: PropTypes.element.isRequired,
};

// Consume hook
export const useAuth = () => useContext(AuthContext);
