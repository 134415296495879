import {
  Card,
  Space,
  Spin,
  Table,
} from '@brooktec/react-ui-kit';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { requestDeleteCurrencyAction } from 'store/slices/constantsSlice';

import AddCurrency from 'components/molecules/AddCurrency';
import DeleteButton from 'components/atoms/DeleteButton';

function CurrencySettings() {
  const dispatch = useDispatch();
  const {
    data: currencies,
    isLoading,
  } = useSelector((state) => state.constants.currencies);

  const deleteCurrency = (id) => dispatch(requestDeleteCurrencyAction(id)).unwrap();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (currency) => (
        <DeleteButton
          onConfirm={() => deleteCurrency(currency.id)}
          errorMessage="You must delete all shareClass/currency relations first"
          popTitle="Are you sure to delete this currency?"
        />
      ),
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Card>
          <h3>Currency list</h3>
          <Table
            columns={columns}
            dataSource={currencies}
            rowKey="id"
            sorter
          />
        </Card>
        <AddCurrency />
      </Space>
    </Spin>
  );
}

export default CurrencySettings;
