const padTo2Digits = (num) => num.toString().padStart(2, '0');

const formatDate = (date) => [
  padTo2Digits(date.getDate()),
  padTo2Digits(date.getMonth() + 1),
  date.getFullYear(),
].join('/');

const upsert = (array, newElm) => {
  const index = array.findIndex((elm) => elm.id === newElm.id);
  return index === -1
    ? [...array, newElm]
    : [
      ...array.slice(0, index),
      newElm,
      ...array.slice(index + 1),
    ];
};

const ARTICLE_TYPES = [
  {
    id: 'Article 6',
    name: 'Article 6',
  },
  {
    id: 'Article 8',
    name: 'Article 8',
  },
  {
    id: 'Article 9',
    name: 'Article 9',
  },
];

export {
  formatDate,
  upsert,
  ARTICLE_TYPES,
};
