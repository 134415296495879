import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Space,
  Spin,
  Switch,
} from '@brooktec/react-ui-kit';

import {
  requestDeleteFundAction,
  requestDeleteFundDocumentAction,
  requestEditFundDocumentAction,
  requestFundAction,
  requestToggleActiveFundAction,
} from 'store/slices/fundSlice';

import DeleteButton from 'components/atoms/DeleteButton';
import DocumentsOverview from 'components/organisms/DocumentsOverview';
import Navbar from 'components/organisms/Navbar';
import OptionsSelector from 'components/molecules/OptionsSelector';
import ShareClassTable from 'components/organisms/ShareClassTable';

import styles from './index.module.css';

function FundDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const fundId = parseInt(params.id, 10);
  const [countryId, setCountryId] = useState();

  const dispatch = useDispatch();
  const { selectedInvestorType } = useSelector((state) => state.options);
  const {
    data: fund,
    isLoading,
  } = useSelector((state) => state.fund);

  const navigateToDashboard = () => {
    navigate('/');
  };

  const navigateToFundEdit = () => {
    navigate(`/fund/${fundId}/edit`);
  };

  const navigateToCreateShareClass = () => {
    navigate(`/shareClass/create?fund=${fundId}`);
  };

  useEffect(() => {
    if (fund?.id !== fundId) {
      dispatch(requestFundAction(fundId))
        .unwrap()
        .catch(() => {
          navigateToDashboard();
        });
    }
  }, [fund]);

  const toggleActiveFund = (
    active,
  ) => dispatch(requestToggleActiveFundAction({ id: fund.id, active }));

  const deleteFund = (id) => dispatch(requestDeleteFundAction(id))
    .then(navigateToDashboard);

  const deleteDocument = (
    documentId,
  ) => dispatch(requestDeleteFundDocumentAction({ id: fund.id, countryId, documentId }));

  const onDocumentUpload = (id, documentId, file) => {
    const document = {
      id: documentId,
      file,
    };

    dispatch(requestEditFundDocumentAction({ id, countryId, document }));
  };

  const shareClasses = useMemo(() => {
    if (fund) {
      return fund.ShareClasses
        .filter(
          (shareClass) => !selectedInvestorType
          || shareClass.InvestorType.id === selectedInvestorType,
        );
    }
    return [];
  }, [fund, selectedInvestorType]);

  return (
    <>
      <Navbar title="Fund detail" />
      <Spin spinning={isLoading}>
        {fund && (
          <div className={styles.container}>
            <Space>
              <h2>
                <b>Fund name: </b>
                <span>{fund.name}</span>
              </h2>
              <Switch loading={isLoading} checked={fund.active} onChange={toggleActiveFund} />
              <Button type="primary" icon={<EditOutlined />} onClick={navigateToFundEdit} />
              <DeleteButton
                onConfirm={() => deleteFund(fund.id)}
                popTitle="Are you sure to delete this fund?"
                disabled={fund.ShareClasses.length > 0 || fund.Documents.length > 0}
              />
            </Space>
            <div>
              <b>Countries: </b>
              <span>{fund.Countries.map((c) => c.name).join(', ')}</span>
            </div>
            <Space direction="vertical" style={{ width: '100%' }}>
              <OptionsSelector showCountry={false} />
              <ShareClassTable data={shareClasses} />
              <Button type="primary" onClick={navigateToCreateShareClass}>Add new share class</Button>
            </Space>
            <DocumentsOverview
              countryId={countryId}
              setCountryId={setCountryId}
              data={fund.Documents}
              deleteDocument={deleteDocument}
              onDocumentUpload={(documentId, file) => onDocumentUpload(fund.id, documentId, file)}
              type="fund"
              edit
            />
          </div>
        )}
      </Spin>
    </>
  );
}

export default FundDetail;
