import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import RequireAuth from 'RequireAuth';
import Dashboard from 'pages/Dashboard';
import Login from 'pages/Login';
import FundDetail from 'pages/FundDetail';
import CreateFund from 'pages/CreateFund';
import EditFund from 'pages/EditFund';
import EditShareClass from 'pages/EditShareClass';
import CreateShareClass from 'pages/CreateShareClass';
import ShareClassDetail from 'pages/ShareClassDetail';
import Settings from 'pages/Settings';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={(
            <RequireAuth />
          )}
        >
          <Route
            index
            element={
              <Dashboard />
            }
          />

          <Route
            path="/fund/:id"
            element={(
              <FundDetail />
            )}
          />
          <Route
            path="/fund/:id/edit"
            element={(
              <EditFund />
            )}
          />
          <Route
            path="/fund/create"
            element={(
              <CreateFund />
            )}
          />
          <Route
            path="/shareClass/:id"
            element={(
              <ShareClassDetail />
            )}
          />
          <Route
            path="/shareClass/:id/edit"
            element={(
              <EditShareClass />
            )}
          />
          <Route
            path="/shareClass/create"
            element={(
              <CreateShareClass />
            )}
          />
          <Route
            path="/settings"
            element={(
              <Settings />
            )}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
