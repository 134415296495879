import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Select, Space } from '@brooktec/react-ui-kit';

import { setSelectedCountryAction, setSelectedInvestorTypeAction } from 'store/slices/optionsSlice';

const { Option } = Select;

function OptionsSelector({
  showCountry,
  showInvestorType,
}) {
  const dispatch = useDispatch();
  const { countries, investorTypes } = useSelector((state) => state.constants);
  const { selectedCountry, selectedInvestorType } = useSelector((state) => state.options);

  const setSelectedCountry = (value) => {
    dispatch(setSelectedCountryAction(value));
  };

  const setSelectedInvestorType = (value) => {
    dispatch(setSelectedInvestorTypeAction(value));
  };

  return (
    <Space>
      {showCountry && (
        <Select
          style={{ width: 130 }}
          placeholder="Country"
          value={selectedCountry}
          onChange={setSelectedCountry}
          allowClear
        >
          {countries.isSuccess && countries.data.map((country) => (
            <Option key={country.id} value={country.id}>
              {country.name}
            </Option>
          ))}
        </Select>
      )}

      {showInvestorType && (
        <Select
          style={{ width: 130 }}
          placeholder="Investor Type"
          value={selectedInvestorType}
          onChange={setSelectedInvestorType}
          allowClear
        >
          {investorTypes.isSuccess && investorTypes.data.map((investorType) => (
            <Option key={investorType.id} value={investorType.id}>
              {investorType.name}
            </Option>
          ))}
        </Select>
      )}
    </Space>
  );
}

OptionsSelector.propTypes = {
  showCountry: PropTypes.bool,
  showInvestorType: PropTypes.bool,
};

OptionsSelector.defaultProps = {
  showCountry: true,
  showInvestorType: true,
};

export default OptionsSelector;
