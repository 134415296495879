import PropTypes from 'prop-types';
import {
  Form,
  Select,
} from '@brooktec/react-ui-kit';

const { Option } = Select;

function FormSelect({
  allowClear,
  data,
  id,
  initialValue,
  label,
  multiple,
  placeholder,
  required,
  requiredText,
}) {
  const uniqueItem = data.length === 1;
  return (
    <Form.Item
      initialValue={
        uniqueItem
          ? data[0].id
          : initialValue
      }
      name={id}
      label={label}
      rules={[
        {
          required, message: requiredText,
        },
      ]}
    >
      <Select
        placeholder={placeholder}
        mode={multiple ? 'multiple' : undefined}
        allowClear={allowClear}
        disabled={uniqueItem}
      >
        {data.map((d) => (
          <Option key={d.id} value={d.id}>{d.name}</Option>
        ))}
      </Select>
    </Form.Item>
  );
}

FormSelect.propTypes = {
  allowClear: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.string.isRequired,
  initialValue: PropTypes.oneOfType(
    PropTypes.number,
    PropTypes.string,
  ),
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  requiredText: PropTypes.string,
};

FormSelect.defaultProps = {
  allowClear: true,
  initialValue: undefined,
  multiple: false,
  required: true,
  requiredText: undefined,
};

export default FormSelect;
