import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  requestFunds,
} from 'services/api';

const initialState = {
  data: undefined,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const requestFundsAction = createAsyncThunk(
  'dashboard/requestFundsAction',
  requestFunds,
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    deleteDashboardFundAction: (state, action) => {
      if (state.data) {
        const deletedFundId = action.payload;
        state.data = state.data.filter((f) => f.id !== deletedFundId);
      }
    },
    deleteDashboardShareClassAction: (state, action) => {
      if (state.data) {
        const deletedShareClassId = action.payload;

        for (let i = 0; i < state.data.length; i += 1) {
          const fund = state.data[i];
          const shareClassFound = fund.ShareClasses.find((s) => s.id === deletedShareClassId);
          if (shareClassFound) {
            fund.ShareClasses = fund.ShareClasses.filter((f) => f.id !== deletedShareClassId);
            break;
          }
        }
      }
    },
    toggleDashboardFundActiveAction: (state, action) => {
      if (state.data) {
        const response = action.payload;

        state.data = state.data.map((f) => (f.id === response.id ? ({
          ...f,
          active: response.active,
        }) : f));
      }
    },
    toggleDashboardShareClassActiveAction: (state, action) => {
      if (state.data) {
        const response = action.payload;

        for (let i = 0; i < state.data.length; i += 1) {
          const fund = state.data[i];
          const shareClassFound = fund.ShareClasses.find((s) => s.id === response.id);
          if (shareClassFound) {
            shareClassFound.active = response.active;
            break;
          }
        }
      }
    },
  },
  extraReducers: {
    [requestFundsAction.pending]: (state) => {
      state.isLoading = true;
    },
    [requestFundsAction.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.data = action.payload;
    },
    [requestFundsAction.rejected]: (state) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});

export const {
  deleteDashboardFundAction,
  deleteDashboardShareClassAction,
  toggleDashboardFundActiveAction,
  toggleDashboardShareClassActiveAction,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
