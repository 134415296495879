import AddDocumentType from 'components/molecules/AddDocumentType';
import {
  Card,
  Space,
  Spin,
  Table,
} from '@brooktec/react-ui-kit';

import DeleteButton from 'components/atoms/DeleteButton';
import { useSelector, useDispatch } from 'react-redux';
import { requestDeleteDocumentAction } from 'store/slices/constantsSlice';

function DocumentSettings() {
  const dispatch = useDispatch();
  const {
    data: documents,
    isLoading,
  } = useSelector((state) => state.constants.documents);

  const deleteDocument = (id) => dispatch(requestDeleteDocumentAction(id));

  const columns = [
    {
      title: 'Document name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: 'Category',
      dataIndex: 'DocumentCategory',
      key: 'DocumentCategory',
      render: (documentCategory) => documentCategory.name,
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (document) => (
        <DeleteButton
          onConfirm={() => deleteDocument(document.id)}
          errorMessage="You must delete all fund/shareClass/sicav/document relations first"
          popTitle="Are you sure to delete this document?"
          disabled={document.locked}
        />
      ),
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Card>
          <h3>Document list</h3>
          <Table
            columns={columns}
            dataSource={documents}
            rowKey="id"
            sorter
          />
        </Card>
        <AddDocumentType />
      </Space>
    </Spin>
  );
}

export default DocumentSettings;
