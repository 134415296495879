import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Form } from '@brooktec/react-ui-kit';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { useAuth } from 'hooks/useAuth';
import logo from 'images/logo-allsolutions.png';

import styles from './index.module.css';

function Login() {
  const navigate = useNavigate();
  const { user, loading, login } = useAuth();
  const [errorMessage, setErrorMessage] = useState(undefined);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, []);

  const onFinish = (values) => {
    const { username, password } = values;
    login(username, password)
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        if (error.response.status === 409) {
          setErrorMessage('Invalid username or password');
        }
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div>
          <img src={logo} alt="allfunds-is-logo" width={256} height={80} />
        </div>
        <Form
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Please input your password!' },
              { min: 15, message: 'Password must be at least 15 characters' },
              { max: 64, message: 'Password must be at most 64 characters' },
              {
                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{15,}$/,
                message: 'Password does not meet the requirements',
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Password"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit" className={styles.loginButton}>
              Log in
            </Button>
          </Form.Item>

          {!loading && errorMessage
            && <div className={styles.errorMessage}>{errorMessage}</div>}
        </Form>
      </div>
    </div>
  );
}

export default Login;
