import PropTypes from 'prop-types';
import {
  Form,
  DatePicker,
} from '@brooktec/react-ui-kit';
import moment from 'moment';

const { RangePicker } = DatePicker;

function FormRangePicker({
  id,
  initialValue,
  label,
  required,
  requiredText,
}) {
  return (
    <Form.Item
      name={id}
      label={label}
      initialValue={initialValue}
      rules={[
        {
          required, message: requiredText,
        },
      ]}
    >
      <RangePicker />
    </Form.Item>
  );
}

FormRangePicker.propTypes = {
  id: PropTypes.string.isRequired,
  initialValue: PropTypes.arrayOf(PropTypes.instanceOf(moment)),
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  requiredText: PropTypes.string,
};

FormRangePicker.defaultProps = {
  initialValue: undefined,
  required: true,
  requiredText: undefined,
};

export default FormRangePicker;
