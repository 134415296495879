import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Card,
  Form,
  message,
} from '@brooktec/react-ui-kit';

import { clearErrorsAction, requestCreateCurrencyAction } from 'store/slices/constantsSlice';

import FormInput from 'components/atoms/FormInput';

function AddCurrency() {
  const dispatch = useDispatch();
  const {
    error,
    isError,
  } = useSelector((state) => state.constants.currencies);

  useEffect(() => {
    if (isError && error) {
      message.error(error);
      dispatch(clearErrorsAction());
    }
  }, [isError]);

  const onFormFinish = (values) => dispatch(requestCreateCurrencyAction(values));

  return (
    <Card>
      <h3>Add new currency</h3>
      <Form
        autoComplete="off"
        onFinish={onFormFinish}
      >
        <FormInput
          id="name"
          label="Currency name"
          placeholder="Name"
        />

        <FormInput
          id="symbol"
          label="Currency symbol"
          placeholder="Symbol"
        />

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default AddCurrency;
