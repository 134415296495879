import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from 'hooks/useAuth';
import { requestConstantsAction } from 'store/slices/constantsSlice';

function Startup({ children }) {
  const dispatch = useDispatch();
  const { user } = useAuth();

  useEffect(async () => {
    if (user) {
      dispatch(requestConstantsAction());
    }
  }, [user]);

  return children;
}

Startup.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Startup;
