import { useNavigate } from 'react-router-dom';

import {
  EditOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import {
  Button,
  Table,
  Space,
  Switch,
} from '@brooktec/react-ui-kit';

import ShareClassTable from 'components/organisms/ShareClassTable';

import DeleteButton from 'components/atoms/DeleteButton';
import { useDispatch, useSelector } from 'react-redux';
import { requestDeleteFundAction, requestToggleActiveFundAction } from 'store/slices/fundSlice';
import { useEffect } from 'react';
import { requestFundsAction } from 'store/slices/dashboardSlice';

function FundsTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data: funds,
  } = useSelector((state) => state.dashboard);

  const {
    isLoading,
  } = useSelector((state) => state.fund);

  const {
    selectedCountry,
    selectedInvestorType,
  } = useSelector((state) => state.options);

  useEffect(() => {
    dispatch(requestFundsAction({
      countryId: selectedCountry,
      investorTypeId: selectedInvestorType,
    }));
  }, [selectedCountry, selectedInvestorType]);

  const navigateToFund = (id) => {
    navigate(`/fund/${id}`);
  };

  const navigateToFundEdit = (id) => {
    navigate(`/fund/${id}/edit`);
  };

  const deleteFund = (id) => dispatch(requestDeleteFundAction(id));

  const toggleActiveFund = (
    id,
    active,
  ) => dispatch(requestToggleActiveFundAction({ id, active }));

  const fundColumns = [
    {
      title: 'Fund',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Countries',
      dataIndex: 'Countries',
      key: 'Countries',
      render: (country) => country.map((c) => c.name).join(', '),
    },
    {
      title: 'Active',
      key: 'active',
      render: (fund) => (
        <Switch
          loading={isLoading}
          checked={fund.active}
          onChange={(active) => toggleActiveFund(fund.id, active)}
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (fund) => (
        <Space>
          <Button type="primary" icon={<EyeOutlined />} onClick={() => navigateToFund(fund.id)} />
          <Button type="primary" icon={<EditOutlined />} onClick={() => navigateToFundEdit(fund.id)} />
          <DeleteButton
            onConfirm={() => deleteFund(fund.id)}
            errorMessage="You must delete all documents in fund"
            popTitle="Are you sure to delete this fund?"
            disabled={fund.ShareClasses.length > 0}
          />
        </Space>
      ),
    },
  ];

  const expandedRow = (shareClasses) => (
    <ShareClassTable
      data={shareClasses}
    />
  );

  return (
    <Table
      columns={fundColumns}
      dataSource={funds}
      expandable={{
        expandedRowRender: (fund) => expandedRow(fund.ShareClasses),
        rowExpandable: (fund) => fund.ShareClasses.length,
      }}
      pagination={false}
      rowKey="id"
      sorter
    />
  );
}

export default FundsTable;
