import { Button, Space } from '@brooktec/react-ui-kit';
import FundsTable from 'components/organisms/FundsTable';
import { useNavigate } from 'react-router-dom';
import OptionsSelector from 'components/molecules/OptionsSelector';

import SicavDocuments from 'components/organisms/SicavDocuments';
import Navbar from 'components/organisms/Navbar';
import styles from './index.module.css';

function Dashboard() {
  const navigate = useNavigate();

  const navigateToCreateFund = () => {
    navigate('/fund/create');
  };

  return (
    <>
      <Navbar title="Dashboard" />
      <div className={styles.container}>
        <SicavDocuments />
        <Space direction="vertical" style={{ width: '100%' }}>
          <h3>Funds list</h3>
          <OptionsSelector />
          <FundsTable />
          <Button type="primary" onClick={navigateToCreateFund}>Add new fund</Button>
        </Space>
      </div>
    </>
  );
}

export default Dashboard;
