import {
  Card,
  Space,
  Spin,
  Table,
} from '@brooktec/react-ui-kit';

import DeleteButton from 'components/atoms/DeleteButton';
import { useSelector, useDispatch } from 'react-redux';
import AddCountry from 'components/molecules/AddCountry';
import { requestDeleteCountryAction } from 'store/slices/constantsSlice';

function CountrySettings() {
  const dispatch = useDispatch();
  const {
    data: countries,
    isLoading,
  } = useSelector((state) => state.constants.countries);

  const deleteCountry = (id) => dispatch(requestDeleteCountryAction(id));

  const columns = [
    {
      title: 'Country name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (country) => (
        <DeleteButton
          onConfirm={() => deleteCountry(country.id)}
          errorMessage="You must delete all fund/country relations first"
          popTitle="Are you sure to delete this country?"
        />
      ),
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Card>
          <h3>Country list</h3>
          <Table
            columns={columns}
            dataSource={countries}
            rowKey="id"
            sorter
          />
        </Card>
        <AddCountry />
      </Space>
    </Spin>

  );
}

export default CountrySettings;
