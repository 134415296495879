import {
  useEffect,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  message,
  Space,
  Spin,
  Switch,
} from '@brooktec/react-ui-kit';

import { EditOutlined } from '@ant-design/icons';

import DocumentsOverview from 'components/organisms/DocumentsOverview';
import Navbar from 'components/organisms/Navbar';
import DeleteButton from 'components/atoms/DeleteButton';
import {
  requestDeleteShareClassAction,
  requestDeleteShareClassDocumentAction,
  requestEditShareClassDocumentAction,
  requestShareClassAction,
  requestToggleActiveShareClassAction,
} from 'store/slices/shareClassSlice';
import PriceTable from 'components/organisms/PriceTable';
import AddPrice from 'components/molecules/AddPrice';
import moment from 'moment';
import styles from './index.module.css';

function ShareClassDetail() {
  const params = useParams();
  const shareClassId = parseInt(params.id, 10);
  const dispatch = useDispatch();
  const [countryId, setCountryId] = useState();

  const {
    data: shareClass,
    isLoading,
  } = useSelector((state) => state.shareClass);

  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate('/');
  };

  const navigateToShareClassEdit = () => {
    navigate(`/shareClass/${shareClassId}/edit`);
  };

  const toggleActiveShareClass = (
    active,
  ) => dispatch(requestToggleActiveShareClassAction({ id: shareClass.id, active }));

  useEffect(async () => {
    if (shareClass?.id !== shareClassId) {
      dispatch(requestShareClassAction(shareClassId))
        .unwrap()
        .catch(() => {
          navigateToDashboard();
        });
    }
  }, [shareClass]);

  const onDocumentUpload = (id, documentId, file) => {
    const document = {
      id: documentId,
      file,
    };

    dispatch(requestEditShareClassDocumentAction({ id, countryId, document }));
  };

  const deleteDocument = (
    documentId,
  ) => dispatch(
    requestDeleteShareClassDocumentAction({ id: shareClass.id, countryId, documentId }),
  );

  const deleteShareClass = (id) => dispatch(requestDeleteShareClassAction(id))
    .unwrap()
    .then(() => navigate(-1))
    .catch((error) => {
      message.error(error);
    });

  return (
    <>
      <Navbar title="Share class detail" />
      <Spin spinning={isLoading}>
        {shareClass && (
          <div className={styles.container}>
            <Space>
              <h2>
                <b>Share class name: </b>
                <span>{shareClass.name}</span>
              </h2>
              <Switch
                loading={isLoading}
                checked={shareClass.active}
                onChange={toggleActiveShareClass}
              />
              <Button type="primary" icon={<EditOutlined />} onClick={navigateToShareClassEdit} />
              <DeleteButton
                onConfirm={() => deleteShareClass(shareClass.id)}
                popTitle="Are you sure to delete this share class?"
                disabled={shareClass.Documents.length > 0}
              />
            </Space>
            <div>
              <b>ISIN: </b>
              <span>{shareClass.isin}</span>
            </div>
            <div>
              <b>Investor Type: </b>
              <span>{shareClass.InvestorType.name}</span>
            </div>
            <div>
              <b>Article Type: </b>
              <span>{shareClass.article}</span>
            </div>
            <div>
              <b>Currency: </b>
              <span>{`${shareClass.Currency.name} - ${shareClass.Currency.symbol}`}</span>
            </div>
            <div>
              <b>Initial Offer Period: </b>
              <span>
                {shareClass.initialOfferPeriodStart ? (
                  `From ${moment(shareClass.initialOfferPeriodStart).format('DD-MM-yyyy')} to ${moment(shareClass.initialOfferPeriodEnd).format('DD-MM-yyyy')}`
                ) : 'Not specified'}
              </span>
            </div>
            <Space direction="vertical" style={{ width: '100%' }}>
              <DocumentsOverview
                countryId={countryId}
                setCountryId={setCountryId}
                investorTypeId={shareClass.InvestorType.id}
                data={shareClass.Documents}
                deleteDocument={deleteDocument}
                onDocumentUpload={
                  (documentId, file) => onDocumentUpload(shareClass.id, documentId, file)
                }
                type="shareClass"
                edit
              />
              <AddPrice shareClassId={shareClass.id} />
              {shareClass?.id === shareClassId && (
                <PriceTable shareClass={shareClass} />
              )}
            </Space>
          </div>
        )}
      </Spin>
    </>

  );
}

export default ShareClassDetail;
