import PropTypes from 'prop-types';
import {
  Button,
  Spin,
  Form,
} from '@brooktec/react-ui-kit';

import FormSelect from 'components/atoms/FormSelect';
import FormInput from 'components/atoms/FormInput';
import { useSelector } from 'react-redux';

function FundForm({
  fund,
  onFinish,
}) {
  const { isLoading } = useSelector((state) => state.fund);
  const { countries, sicavs } = useSelector((state) => state.constants);

  return (
    <Spin spinning={isLoading}>
      <Form
        autoComplete="off"
        onFinish={onFinish}
        wrapperCol={{ span: 10 }}
      >
        <FormInput
          id="name"
          initialValue={fund && fund.name}
          label="Fund name"
          placeholder="Name"
        />

        {sicavs.isSuccess
          ? (
            <FormSelect
              allowClear={false}
              data={sicavs.data}
              id="sicavId"
              label="Sicav"
              placeholder="Select a sicav"
            />
          ) : <Spin />}

        {countries.isSuccess
          ? (
            <FormSelect
              data={countries.data}
              id="countryIds"
              initialValue={fund && fund.Countries.map((c) => c.id)}
              label="Countries"
              placeholder="Select one or more countries"
              multiple
            />
          ) : <Spin />}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {fund ? 'Save changes' : 'Create'}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
}

FundForm.propTypes = {
  fund: PropTypes.shape({
    name: PropTypes.string.isRequired,
    Countries: PropTypes.arrayOf(PropTypes.object).isRequired,
    Documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
  onFinish: PropTypes.func.isRequired,
};

FundForm.defaultProps = {
  fund: undefined,
};

export default FundForm;
