import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  requestCreatePriceShareClass,
  requestCreatePriceShareClassDocument,
  requestCreateShareClass,
  requestDeletePrice,
  requestDeleteShareClass,
  requestDeleteShareClassDocument,
  requestEditShareClass,
  requestEditShareClassDocument,
  requestPrices,
  requestShareClass,
  requestToggleActiveShareClass,
} from 'services/api';
import { upsert } from 'utils';
import { deleteDashboardShareClassAction, toggleDashboardShareClassActiveAction } from './dashboardSlice';
import {
  createFundShareClass,
  deleteFundShareClassAction,
  requestFundAction,
  // requestFundAction,
  toggleFundShareClassActiveAction,
} from './fundSlice';

const initialState = {
  data: undefined,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const requestShareClassAction = createAsyncThunk(
  'shareClass/requestShareClassAction',
  requestShareClass,
);

export const requestCreatePriceShareClassAction = createAsyncThunk(
  'shareClass/requestCreatePriceShareClassAction',
  async (params, { rejectWithValue }) => {
    const {
      shareClassId,
      values,
    } = params;

    try {
      const response = await requestCreatePriceShareClass(shareClassId, values);
      return response;
    } catch (error) {
      const errorMessage = error.response.data.message;
      throw rejectWithValue(errorMessage);
    }
  },
);

export const requestCreatePriceShareClassDocumentAction = createAsyncThunk(
  'shareClass/requestCreatePriceShareClassDocumentAction',
  async (params, { rejectWithValue }) => {
    const {
      shareClassId,
      file,
    } = params;

    try {
      const response = await requestCreatePriceShareClassDocument(shareClassId, file);
      return response;
    } catch (error) {
      const errorMessage = error.response.data.message;
      throw rejectWithValue(errorMessage);
    }
  },
);

export const requestCreateShareClassAction = createAsyncThunk(
  'shareClass/requestCreateShareClassAction',
  async (params, { dispatch }) => {
    const {
      valuesWithFund: values,
    } = params;

    const createdShareClass = await requestCreateShareClass(values);
    const shareClass = await requestShareClass(createdShareClass.id);
    dispatch(createFundShareClass(shareClass));
    return shareClass;
  },
);

export const requestDeletePriceAction = createAsyncThunk(
  'shareClass/requestDeletePriceAction',
  async (price) => {
    await requestDeletePrice(price.id);
    return price.id;
  },
);

export const requestDeleteShareClassAction = createAsyncThunk(
  'shareClass/requestDeleteShareClassAction',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      await requestDeleteShareClass(id);
      dispatch(deleteFundShareClassAction(id));
      dispatch(deleteDashboardShareClassAction(id));
    } catch (error) {
      const errorMessage = error.response.data.message;
      throw rejectWithValue(errorMessage);
    }
  },
);

export const requestDeleteShareClassDocumentAction = createAsyncThunk(
  'shareClass/requestDeleteShareClassDocumentAction',
  async (params, { dispatch }) => {
    const {
      id,
      documentId,
      countryId,
    } = params;

    await requestDeleteShareClassDocument(id, countryId, documentId);
    dispatch(requestShareClassAction(id));
  },
);

export const requestEditShareClassAction = createAsyncThunk(
  'shareClass/requestEditShareClassAction',
  async (params, { dispatch }) => {
    const {
      id,
      fundId,
      values,
    } = params;

    await requestEditShareClass(id, values);
    dispatch(requestShareClassAction(id));
    dispatch(requestFundAction(fundId));
  },
);

export const requestPricesAction = createAsyncThunk(
  'shareClass/requestPricesAction',
  (args) => {
    const {
      id,
      params,
    } = args;
    return requestPrices(id, params);
  },
);

export const requestEditShareClassDocumentAction = createAsyncThunk(
  'shareClass/requestEditShareClassDocumentAction',
  async (params, { dispatch }) => {
    const {
      id,
      countryId,
      document,
    } = params;

    await requestEditShareClassDocument(id, countryId, document);
    dispatch(requestShareClassAction(id));
  },
);

export const requestToggleActiveShareClassAction = createAsyncThunk(
  'shareClass/requestToggleActiveShareClassAction',
  async (params, { dispatch }) => {
    const {
      id,
      active,
    } = params;

    await requestToggleActiveShareClass(id, active);
    dispatch(toggleFundShareClassActiveAction(params));
    dispatch(toggleDashboardShareClassActiveAction(params));
    return params;
  },
);

export const shareClassSlice = createSlice({
  name: 'shareClass',
  initialState,
  reducers: {},
  extraReducers: {
    [requestShareClassAction.pending]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [requestShareClassAction.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [requestShareClassAction.rejected]: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    [requestCreatePriceShareClassAction.pending]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [requestCreatePriceShareClassAction.fulfilled]: (state, action) => {
      const newPrice = action.payload;

      const existingPrices = state.data.prices.rows;
      const newPrices = upsert(existingPrices, newPrice);

      if (existingPrices.length !== newPrices.length) {
        state.data.prices.count += 1;
      }

      state.data.prices.rows = newPrices;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [requestCreatePriceShareClassAction.rejected]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [requestCreatePriceShareClassDocumentAction.pending]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [requestCreatePriceShareClassDocumentAction.fulfilled]: (state, action) => {
      const pricesToUpsert = action.payload;
      const existingPrices = state.data.prices.rows;
      let newPrices = JSON.parse(JSON.stringify(existingPrices));

      pricesToUpsert.forEach((newPrice) => {
        newPrices = upsert(newPrices, newPrice);
      });

      if (existingPrices.length !== newPrices.length) {
        state.data.prices.count += (newPrices.length - existingPrices.length);
      }

      state.data.prices.rows = newPrices;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [requestCreatePriceShareClassDocumentAction.rejected]: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    [requestCreateShareClassAction.pending]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [requestCreateShareClassAction.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [requestCreateShareClassAction.rejected]: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    [requestDeletePriceAction.pending]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [requestDeletePriceAction.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;

      const deletedPriceId = action.payload;

      state.data.prices.rows = state.data.prices.rows.filter((p) => p.id !== deletedPriceId);
      state.data.prices.count -= 1;
    },
    [requestDeletePriceAction.rejected]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [requestDeleteShareClassAction.pending]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [requestDeleteShareClassAction.fulfilled]: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [requestDeleteShareClassAction.rejected]: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    [requestDeleteShareClassDocumentAction.pending]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [requestDeleteShareClassDocumentAction.fulfilled]: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [requestDeleteShareClassDocumentAction.rejected]: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    [requestEditShareClassAction.pending]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [requestEditShareClassAction.fulfilled]: () => {
    },
    [requestEditShareClassAction.rejected]: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    [requestPricesAction.fulfilled]: (state, action) => {
      if (state.data) {
        if (!state.data.prices) {
          state.data.prices = action.payload;
        } else {
          state.data.prices.rows.push(...action.payload.rows);
        }
      }
    },
    [requestToggleActiveShareClassAction.pending]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [requestToggleActiveShareClassAction.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      const response = action.payload;

      if (state.data?.id === response.id) {
        state.data.active = response.active;
      }
    },
    [requestToggleActiveShareClassAction.rejected]: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
  },
});

export default shareClassSlice.reducer;
