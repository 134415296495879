import ReactDOM from 'react-dom';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@brooktec/react-ui-kit';

import Router from 'router';
import { ProvideAuth } from 'hooks/useAuth';
import store from 'store';
import Startup from 'startup';

import '@brooktec/react-ui-kit/dist/antd.variable.min.css';
import './globals.css';

const theme = {
  primaryColor: '#242C4C',
};

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <ProvideAuth>
        <Startup>
          <ThemeProvider theme={theme}>
            <Router />
          </ThemeProvider>
        </Startup>
      </ProvideAuth>
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);
