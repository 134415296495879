import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import {
  EditOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import {
  Button,
  Space,
  Table,
  Switch,
  message,
} from '@brooktec/react-ui-kit';

import DeleteButton from 'components/atoms/DeleteButton';
import { requestDeleteShareClassAction, requestToggleActiveShareClassAction } from 'store/slices/shareClassSlice';
import { useDispatch, useSelector } from 'react-redux';

function ShareClassTable({ data }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isLoading,
  } = useSelector((state) => state.shareClass);
  const navigateToShareClass = (id) => {
    navigate(`/shareClass/${id}`);
  };

  const navigateToShareClassEdit = (id) => {
    navigate(`/shareClass/${id}/edit`);
  };

  const deleteShareClass = (id) => {
    dispatch(requestDeleteShareClassAction(id))
      .unwrap()
      .catch((error) => {
        message.error(error);
      });
  };

  const toggleActiveShareClass = (
    id,
    active,
  ) => dispatch(requestToggleActiveShareClassAction({ id, active }));

  const columns = [
    {
      title: 'Share class',
      dataIndex: 'name',
      key: 'name',
    },
    { title: 'Isin', dataIndex: 'isin', key: 'isin' },
    { title: 'Article', dataIndex: 'article', key: 'article' },
    {
      title: 'Investor type',
      dataIndex: 'InvestorType',
      key: 'InvestorType',
      render: (investorType) => (
        investorType.name
      ),
    },
    {
      title: 'Active',
      key: 'active',
      render: (shareClass) => (
        <Switch
          loading={isLoading}
          checked={shareClass.active}
          onChange={(active) => toggleActiveShareClass(shareClass.id, active)}
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (shareClass) => (
        <Space>
          <Button type="primary" icon={<EyeOutlined />} onClick={() => navigateToShareClass(shareClass.id)} />
          <Button type="primary" icon={<EditOutlined />} onClick={() => navigateToShareClassEdit(shareClass.id)} />
          <DeleteButton
            onConfirm={() => deleteShareClass(shareClass.id)}
            errorMessage="You must delete all documents in share class first"
            popTitle="Are you sure to delete this share class?"
          />
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey="id"
    />
  );
}

ShareClassTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ShareClassTable;
