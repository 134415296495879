import PropTypes from 'prop-types';
import {
  Button,
  Spin,
  Form,
} from '@brooktec/react-ui-kit';

import FormInput from 'components/atoms/FormInput';
import { useSelector } from 'react-redux';
import FormSelect from 'components/atoms/FormSelect';
import { ARTICLE_TYPES } from 'utils';
import FormRangePicker from 'components/atoms/FormRangePicker';
import moment from 'moment';

function ShareClassForm({
  shareClass,
  onFinish,
}) {
  const {
    data: investorTypes,
  } = useSelector((state) => state.constants.investorTypes);
  const {
    data: currencies,
  } = useSelector((state) => state.constants.currencies);

  return (
    <Form
      autoComplete="off"
      onFinish={onFinish}
      wrapperCol={{ span: 10 }}
    >
      <FormInput
        id="name"
        initialValue={shareClass && shareClass.name}
        label="Share Class name"
        placeholder="Name"
      />

      <FormInput
        id="isin"
        initialValue={shareClass && shareClass.isin}
        label="ISIN"
        placeholder="Isin"
      />

      <FormSelect
        allowClear={false}
        data={ARTICLE_TYPES}
        id="article"
        initialValue={shareClass && shareClass.article}
        label="Article Type"
        placeholder="Select an article type"
      />

      {investorTypes
        ? (
          <FormSelect
            allowClear={false}
            data={investorTypes}
            id="investorTypeId"
            initialValue={shareClass && shareClass.InvestorType.id}
            label="Investor Type"
            placeholder="Select an investor type"
          />
        ) : <Spin />}

      {currencies
        ? (
          <FormSelect
            allowClear={false}
            data={currencies}
            id="currencyId"
            initialValue={shareClass && shareClass.Currency.id}
            label="Currency"
            placeholder="Select a currency"
          />
        ) : <Spin />}

      <FormRangePicker
        id="initialOfferPeriod"
        label="Initial Offer Period"
        initialValue={shareClass?.initialOfferPeriodStart && [
          moment(shareClass.initialOfferPeriodStart),
          moment(shareClass.initialOfferPeriodEnd),
        ]}
        required={false}
      />

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {shareClass ? 'Save changes' : 'Create'}
        </Button>
      </Form.Item>
    </Form>
  );
}

ShareClassForm.propTypes = {
  shareClass: PropTypes.shape({
    name: PropTypes.string.isRequired,
    isin: PropTypes.string.isRequired,
    article: PropTypes.string.isRequired,
    InvestorType: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    Currency: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    Documents: PropTypes.arrayOf(PropTypes.object).isRequired,
    initialOfferPeriodStart: PropTypes.string,
    initialOfferPeriodEnd: PropTypes.string,
  }),
  onFinish: PropTypes.func.isRequired,
};

ShareClassForm.defaultProps = {
  shareClass: undefined,
};

export default ShareClassForm;
