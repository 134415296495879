import PropTypes from 'prop-types';
import {
  BackwardOutlined,
  HomeOutlined,
  LogoutOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Button, Space } from '@brooktec/react-ui-kit';
import { useAuth } from 'hooks/useAuth';

import { useLocation, useNavigate } from 'react-router-dom';
import styles from './index.module.css';

function Navbar({
  title,
}) {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateBack = () => {
    navigate(-1);
  };

  const navigateToDashboard = () => {
    navigate('/');
  };

  const navigateToSettings = () => {
    navigate('/settings');
  };

  return (
    <nav className={styles.container}>
      {location.pathname === '/'
        ? <h3>{`Hello ${user.username}`}</h3>
        : (
          <Space>
            <Button
              onClick={navigateToDashboard}
              icon={<HomeOutlined />}
            />
            <Button
              onClick={navigateBack}
              icon={<BackwardOutlined />}
            >
              Back
            </Button>
          </Space>
        )}
      <h1>{title}</h1>
      <Space>
        {location.pathname !== '/settings' && (
          <Button
            onClick={navigateToSettings}
            icon={<SettingOutlined />}
          />
        )}

        <Button
          onClick={logout}
          className={styles.logoutButton}
          icon={<LogoutOutlined />}
        >
          Logout
        </Button>
      </Space>
    </nav>
  );
}

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Navbar;
